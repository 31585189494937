import { takeLatest, put } from "@redux-saga/core/effects";
import { baseurl } from "../../components/Common/Axios";
import axios_original from 'axios';
import { LOGIN_ASYNC, LOGOUT_ASYNC, LOGIN_FAILED, CLEAR_CREDENTIALS } from "../actions/actions";


export function* watchLogin() {

    yield takeLatest("LOGIN", loginAsync);

    yield takeLatest("LOGOUT", logoutAsync);
}

// export function* watchLogout() {

//     yield takeLatest("LOGOUT", logoutAsync);
// }
function* loginAsync(action) {

    try {

        const response = yield axios_original.post(baseurl + "/auth/sign_in.json", { username: action.username, password: action.password })
        console.log("THIS IS COMPANY INFO")
        console.log(response);
        console.log("ACCESS_TOKEN", response.headers["access-token"])
        yield put({ type: LOGIN_ASYNC, user: response.data, token: response.headers["access-token"], uid: response.headers.uid, client: response.headers.client, user: response.data, company: response.data.data.company })
    }
    catch (error) {
        console.log("LOGIN FAILED BISMALLY", error)
        yield put({ type: LOGIN_FAILED, error: error })
    }

}
function* logoutAsync(action) {
    console.log(action);
    try {
        const response = yield axios_original.delete(baseurl + "/auth/sign_out.json", { headers: { "access-token": action.token, uid: action.uid, client: action.client } })
        yield put({ type: LOGOUT_ASYNC, token: response.headers.authorization })
    }
    catch (error) {
        yield put({ type: CLEAR_CREDENTIALS })
    }
}

