import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

import { connect } from 'react-redux';

class SidebarUserBlock extends Component {



    render() {
        return (

            <div>
                <div className="item user-block">
                    {/* User picture */}
                    <div className="user-block-picture">
                        <div className="user-block-status">
                            {/* <img className="img-thumbnail rounded-circle" src="img/user/02.jpg" alt="Avatar" width="60" height="60" /> */}
                            <i className="fas fa-user-circle"></i>
                            <div className="circle bg-success circle-lg"></div>
                        </div>
                    </div>
                    {/* Name and Job */}
                    <div className="user-block-info">
                        <span className="user-block-name">Hello, {this.props.user.first_name}</span>
                        <span className="user-block-role">{this.props.user.role.name}</span>
                    </div>
                </div>
            </div>
        )
    }
}

SidebarUserBlock.propTypes = {
    showUserBlock: PropTypes.bool
};

const mapStateToProps = state => ({ showUserBlock: state.settings.showUserBlock, user: state.authentication.user.data })

export default connect(
    mapStateToProps
)(SidebarUserBlock);