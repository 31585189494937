import axios from 'axios';
import { takeLatest, put } from "@redux-saga/core/effects";
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions'
import { connect } from 'react-redux'
// import configureStore from './store/store';x
import configureStore from '../../store/store'
// import { dispatch } from "redux"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react'
import MyToast from './MyToast'
// import { dispatch } from 'rxjs/internal/observable/pairs';
import { PASSWORD_EXPIRED } from '../../store/actions/actions';
const { dispatch } = configureStore;
export const requestHandler = (request, token) => {
  // if (isHandlerEnabled(request)) {
  //   // Modify request here
  request.headers['Authorization'] = token
  // }

  return request
}
var base = window.location.origin;
console.log("BASEURL", base)
export var baseurl = 'https://liveapi.nicmid.com';
const axiosInstance = axios.create({
  baseURL: baseurl,
})

axiosInstance.interceptors.request.use(function (config) {
  config.headers = {
    'Content-Type': 'application/json',
    "access-token": localStorage.getItem("token"),
    "uid": localStorage.getItem("uid"),
    "client": localStorage.getItem("client")
  }
  return config;
});



export function errorMessages(errors) {
  var string = []
  var keys = Object.keys(errors.errors);
  errors = errors.errors
  keys.map((row, i) => {
    if (row == 'full_messages') {
      return false;
    }
    var new_row = row.replace("_", " ");
    new_row = new_row.charAt(0).toUpperCase() + new_row.slice(1)
    return string.push(`${errors[row]}`)
  })
  return string;
}
const mapStateToProps = (state) => {
  return (
    { user: state.authentication.user, token: state.authentication.token }
  )
}
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })
export default connect(mapStateToProps, mapDispatchToProps)(axiosInstance);
