import React, { Component } from 'react';

import Header from './Header'
import Sidebar from './Sidebar'
import Offsidebar from './Offsidebar'

import Footer from './Footer'
import Login from '../Pages/Login'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions'
import { ToastContainer } from 'react-toastify'
import FeedbackModal from '../Modals/FeedbackModal'
import { Container, Button, Link, lightColors, darkColors } from 'react-floating-action-button'


class Base extends Component {
    state = {
        user: {
            first_name: "",
            last_name: "",
            email: "",
            usename: "",
            phone: ""

        },
        showFeedbackDialog: false
    }

    user_signed_in = () => {
        localStorage.getItem("MID_TOKEN").then((val) => {
            if (val == "") {
                this.setState({ token: false })
            }
            else {
                this.setState({ token: val })
            }
        })
    }
    toggleFeedbackDialog = () => {
        console.log("toggling stuff")
        this.setState({
            ...this.state,
            showFeedbackDialog: !this.state.showFeedbackDialog
        })
    }

    config = {
        headers: {
            Authorization: "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MiwiZXhwIjoxNTY5OTMwNTgxfQ.pPL-aG9iwOWST-IpYmc4O0zAY_YTGEtBTiT1WKGDleM",
        }
    }
    render() {

        console.log(this.props.token)
        if (this.props.token == "" || this.props.token == null) {
            return (<Login />)
        }
        else {
            return (
                <div className="wrapper">
                    <FeedbackModal isOpen={this.state.showFeedbackDialog} toggle={this.toggleFeedbackDialog} parentForm={this} />

                    <Header />

                    <Sidebar />

                    <Offsidebar />

                    <section className="section-container">
                        {this.props.children}
                    </section>

                    <Footer />
                    <Container>
                        {/* <Button
                            tooltip="Create note link"
                            icon="far fa-sticky-note" /> */}
                        <Button
                            tooltip="Access Documentation"
                            styles={{ backgroundColor: darkColors.blue, color: lightColors.white }}
                            icon="fas fa-book-open"
                            onClick={(e) => { e.preventDefault(); window.open("https://docs.nicmid.com", '_blank'); }} />

                        <Button
                            tooltip="Give us Feedback"
                            icon="fas fa-question"
                            rotate={false}

                            styles={{ backgroundColor: darkColors.green, color: lightColors.white }}
                            onClick={this.toggleFeedbackDialog}
                        />
                    </Container>
                </div>
            )
        }
    }

}

const mapStateToProps = (state) => {
    return (
        { token: state.authentication.token }
    )
}

const mapDispatchToProps = (dispatch) => {
    return ({
        actions: bindActionCreators(actions, dispatch)
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(Base);
