import React, { Component } from 'react';
import LoginPage from '../Pages/Login'

class BasePage extends Component {
    render() {
        return (
            <div><h2>Hello There</h2>
                this.props.children
            </div>
        )
    }
}
export default BasePage;
