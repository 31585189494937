import React, { Component } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, FormGroup, Input, } from 'reactstrap'
import axios from '../Common/Axios'


export default class ChangePasswordModal extends Component {
    state = {
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
    }
    onChange = e => {
        var name = e.target.name;

        this.setState({
            ...this.state,
            [name]: e.target.value
        })
    }
    changePassword = e => {
        e.preventDefault();
        console.log("ChangingPassword");
        axios.put("/users/change_password", { current_password: this.state.current_password, password: this.state.new_password, password_confirmation: this.state.new_password_confirmation }).then(response => {
            this.setState({
                ...this.state,
                current_password: '',
                new_password: '',
                new_password_confirmation: ''
            });
            console.log("Logging the Password")
            console.log(response)
            if (response != undefined) {
                this.props.toggle();
            }


        }).catch(error => {
            this.setState({
                ...this.state,
                current_password: '',
                new_password: '',
                new_password_confirmation: ''
            })
            // this.props.toggle();
        })
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>Change Password</ModalHeader>
                <ModalBody>

                    <FormGroup row>
                        <label className="col-xl-12 col-form-label">Current Password</label>
                        <div className="col-xl-12">
                            <Input type="password" name='current_password' placeholder="" onChange={this.props.onChange} value={this.state.current_password} onChange={this.onChange} />
                        </div>
                    </FormGroup>
                    <FormGroup row>
                        <label className="col-xl-12 col-form-label">New Password</label>
                        <div className="col-xl-12">
                            <Input type="password" name='new_password' placeholder="" onChange={this.props.onChange} value={this.state.new_password} onChange={this.onChange} />
                        </div>
                    </FormGroup>
                    <FormGroup row>
                        <label className="col-xl-12 col-form-label">Confirm New Password</label>
                        <div className="col-xl-12">
                            <Input type="password" name='new_password_confirmation' placeholder="" onChange={this.props.onChange} value={this.state.new_password_confirmation} onChange={this.onChange} />
                        </div>
                    </FormGroup>

                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.changePassword}>Change Password</Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal >
        )
    }
}
