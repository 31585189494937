import { LOGIN_ASYNC, LOGOUT_ASYNC, LOGIN_FAILED, PASSWORD_EXPIRED, CLEAR_CREDENTIALS, SET_LOGIN_LOADING, CLEAR_LOGIN_LOADING } from '../actions/actions';

import { errorMessages } from '../../components/Common/Axios';
const initialState = {
    user: {},
    loginErrorMessage: "",
    username: ""
}

const authenticationReducer = (state = initialState, action) => {
    console.log(action.type)
    switch (action.type) {
        case SET_LOGIN_LOADING:
            console.log("In reducer")
            state = {
                ...initialState,
                loading: true,
            }

            return state
        case CLEAR_LOGIN_LOADING:
            console.log("In reducer")
            state = {
                ...initialState,
                loading: false,
            }

            return state
        case LOGIN_ASYNC:
            localStorage.setItem("token", action.token)
            localStorage.setItem("uid", action.uid)
            localStorage.setItem("client", action.client)
            localStorage.setItem("user", action.user)
            localStorage.setItem("company", action.company)
            state = {
                ...initialState,
                user: action.user,
                token: action.token,
                uid: action.uid,
                client: action.client,
                user: action.user,
                company: action.company,
                loading: false,
            }
            return state
        case LOGIN_FAILED:
            console.log("LOGIN FAILED>>>")
            localStorage.setItem("token", "")
            localStorage.setItem("uid", "")
            localStorage.setItem("client", "")
            localStorage.setItem("user", "")
            localStorage.setItem("company", "")
            console.log(action)
            if (action.hasOwnProperty('error')) {
                // console.log("HAS ERROR")
                // console.log("THIS IS THE RROR", action.error.response.data.errors)
                // console.log("THIS IS THE TYPE OF ERROR", typeof action.error)
                // console.log(typeof action.error)
                state = {
                    ...initialState,
                    user: "",
                    token: "",
                    uid: "",
                    client: "",
                    user: "",
                    company: "",
                    loading: false,
                    loginErrorMessage: typeof action.error == 'string' ? action.error : errorMessages(action.error.response.data)
                }
            }
            else {
                state = {
                    ...initialState,
                    user: "",
                    token: "",
                    uid: "",
                    client: "",
                    user: "",
                    company: "",
                    loading: false,
                    loginErrorMessage: errorMessages("Something Strange Happened")

                }
            }
            return state
        case PASSWORD_EXPIRED:
            console.log("REQUIRING PASSWORD>>>>>>>>>>>>")
            state = {
                ...initialState,
                password_expired: true
            }
            return state
        case LOGOUT_ASYNC:
            console.log("LOGINS OUT USER")
            localStorage.removeItem("token");
            localStorage.removeItem("uid");
            localStorage.removeItem("client");
            localStorage.removeItem("user");
            localStorage.removeItem("company");

            state = {
                ...initialState,
                user: {},
                token: null,
                uid: null,
                client: null,
                user: null,
                company: null,
            }
            return state

        case CLEAR_CREDENTIALS:
            console.log("CLEaring again man...")
            console.log(action.error)
            localStorage.removeItem("token");
            localStorage.removeItem("uid");
            localStorage.removeItem("client");
            localStorage.removeItem("user");
            localStorage.removeItem("company");
            state = {};
            if (action.hasOwnProperty('error')) {
                state = {
                    ...initialState,
                    user: {},
                    token: null,
                    uid: null,
                    client: null,
                    user: null,
                    company: null,
                    loginErrorMessage: errorMessages(action.error.response.data)
                }
            }
            else {
                state = {
                    ...initialState,
                    user: {},
                    token: null,
                    uid: null,
                    client: null,
                    user: null,
                    company: null

                }
            }

            return state

        // case SETUSER:
        //     state = {
        //         ...initialState,
        //         user: action.user,
        //         mid_token: action.token
        //     }

        // return state;
        default:
            console.log("NOTHING IS CHANGING")
            return state;
    }
}




export default authenticationReducer;