import { dispatch } from "rxjs/internal/observable/pairs";
// import { dispatch } from 'redux'
import Store from '../store'
export const LOGIN = 'LOGIN';
export const LOGIN_ASYNC = 'LOGIN_ASYNC';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_ASYNC = 'LOGOUT_ASYNC';
export const SETUSER = 'SETUSER';
export const CLEAR_CREDENTIALS = 'CLEAR_CREDENTIALS';
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING'
export const CLEAR_LOGIN_LOADING = 'CLEAR_LOGIN_LOADING'

export function login(username, password) {
    return { type: LOGIN, username, password };
}
// export function setUserInfo(user, token, uid, client, company) {
//     return { type: SETUSER, user, token, uid, client, company }
// }

export function logout(token, uid, client, company) {
    return { type: LOGOUT, token, uid, client, company }
}

export function begin_login() {
    console.log("STARTING LOGIN STUFF")
    return { type: SET_LOGIN_LOADING }
}

export function clear_login_loading() {
    return { type: CLEAR_LOGIN_LOADING }
}

export function password_expired() {
    console.log("Loggin out user for expired account");
    return { type: CLEAR_CREDENTIALS }
}

export function clear_credentials(error) {
    console.log("Clearing Credentialss");
    return { type: CLEAR_CREDENTIALS, error }
    // dispatch({ type: CLEAR_CREDENTIALS })
}