import axiosInstance from '../Common/Axios'

var branches = []
var roles = []
var capacity_types = []
var cover_types = []
var customer_types = []
var schedules = []
var currencies = []
var computation_types = []
var intermediary_types = []
var company_types = []
var body_types = []
var tarriff = {}
var sticker_price = 0.00
var payment_modes = []
var excess_types = []
var insurance_companies = []
var companies = []
var sticker_balance = 0
var usage_types = [];
var discount_types = [];
var id_card_types = [];
var customers_count = 0;
var comprehensive_policies_count = 0;
var third_party_fnf_count = 0;
var third_party_count = 0;
var total_stickers = 0;
var insurance_companies_data = [];
var loading_types = [];
var gross_premium_written = 0;
var stickers_issued = 0;
var completedStickerPurchases = 0;
var short_rate = {}
var short_rates = []
var permissions = []
var endorsement_types = []
var brokerage_firms = []
var banks = []
var sms_balance = null

export async function getBranches() {
    await axiosInstance.get('/branches').then((response) => {

        if (response.status == "UNAUTHENTICATED") {

        }
        else {

            branches = response.data.map((row, i) => {
                return { value: row.id, label: row.name }
            });
        }

    }).catch(error => {


    })

    return branches
}

export async function getIdCardTypes() {


    await axiosInstance.get('/id_card_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            id_card_types = response.data.map((row, i) => {
                return { value: row.id, label: row.name }
            });
        }
    }).catch(error => {

    })

    return id_card_types
}
export async function getRoles() {


    await axiosInstance.get('/roles').then((response) => {

        if (response.status == "UNAUTHENTICATED") {

        }
        else {

            roles = response.data.map((row, i) => {
                return { value: row.id, label: row.name }
            });


        }

    }).catch(error => {


    })

    return roles
}

export async function getCapacityTypes() {


    await axiosInstance.get('/capacity_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            capacity_types = response.data.map((row, i) => {
                return { value: row.id, label: row.name }
            });
        }

    }).catch(error => {

        capacity_types = []
    })

    return capacity_types;
}
export async function getEndorsementTypes() {


    await axiosInstance.get('/endorsement_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            endorsement_types = response.data.map((row, i) => {
                return { value: row.id, label: row.name }
            });
        }

    }).catch(error => {

        endorsement_types = []
    })

    return endorsement_types;
}
export async function getUsageTypes() {


    await axiosInstance.get('/usage_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            usage_types = response.data.map((row, i) => {
                return { value: row.id, label: row.name }
            });
        }

    }).catch(error => {

        usage_types = []
    })

    return usage_types;
}

export async function getCoverTypes() {

    await axiosInstance.get('/cover_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            cover_types = response.data.map((row, i) => {
                return { value: row.id, label: row.name }
            });
        }

    }).catch(error => {
        cover_types = []

    })

    return cover_types;
}
export async function getSchedules() {

    await axiosInstance.get('/schedules').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            schedules = response.data.map((row, i) => {
                return { value: row.id, label: row.name, usage_type_id: row.usage_type_id }
            });
        }
    }).catch(error => {

        schedules = []
    })

    return schedules || {};
}
export async function getCurrencies() {

    await axiosInstance.get('/currencies').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            currencies = response.data.map((row, i) => {

                return { value: row.id, label: `${row.symbol} - ${row.name}`, exchange_rate: row.exchange_rate }
            });
        }

    }).catch(error => {
        currencies = []


    })

    return currencies;
}

export async function getComputationTypes() {

    await axiosInstance.get('/computation_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            computation_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, days: row.days }
            });
        }

    }).catch(error => {

        computation_types = []
    })

    return computation_types;
}



export async function getCompanyTypes() {

    await axiosInstance.get('/company_types').then((response) => {
        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            company_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}` }
            });
        }

    }).catch(error => {

        company_types = []
    })

    return company_types;
}
export async function getIntermediaryTypes() {

    await axiosInstance.get('/intermediary_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            intermediary_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}` }
            });
        }

    }).catch(error => {

        intermediary_types = []
    })

    return intermediary_types;
}
// export async function companyTypes() {

//     await axiosInstance.get('/company_types').then((response) => {

//         if (response.status == "UNAUTHENTICATED") {
//         }
//         else {
//             company_types = response.data.map((row, i) => {
//                 return { value: row.id, label: `${row.name}` }
//             });
//         }

//     }).catch(error => {

//         company_types = []
//     })
//     return company_types;
// }

export async function getBodyTypes() {

    await axiosInstance.get('/body_types').then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {

            body_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, seats: row.seats, additional_seats: row.additional_seats }
            });
        }
    }).catch(error => {
        body_types = []
    })
    return body_types;
}
export async function getTarriff(schedule_id, year) {

    await axiosInstance.get(`/tarriffs/get_tarriff?schedule_id=${schedule_id}&year=${year}`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            // body_types = response.data.map((row, i) => {
            //     // return { value: row.id, label: `${row.name}`, seats: row.seats }
            // });
            tarriff = response.data
        }
    }).catch(error => {
        return null;
    })

    return tarriff;
}
export async function getShortRate(days, cover_type_id) {

    await axiosInstance.get(`/motor_short_rates/get_short_rate?days=${days}&cover_type=${cover_type_id}`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {

            short_rate = response.data
        }
    }).catch(error => {
        return null;
    })

    return short_rate;
}
export async function getShortRates() {

    await axiosInstance.get(`/motor_short_rates/?full=true`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {

            short_rates = response.data
        }
    }).catch(error => {
        return null;
    })

    return short_rates;
}
export async function getPermissions() {

    await axiosInstance.get(`/permissions/`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {

            permissions = response.data
        }
    }).catch(error => {
        return [];
    })

    return permissions;
}
export async function getStickerPrice() {

    await axiosInstance.get(`/sticker_purchases/get_price?`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            // body_types = response.data.map((row, i) => {
            //     // return { value: row.id, label: `${row.name}`, seats: row.seats }
            // });
            sticker_price = response.data
        }
    }).catch(error => {
        sticker_price = 0.0
    })

    return sticker_price;
}
export async function getPaymentModes() {

    await axiosInstance.get(`/payment_modes`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            payment_modes = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}` }
            });

        }
    }).catch(error => {
        payment_modes = []
    })

    return payment_modes;
}
export async function getExcessTypes() {

    await axiosInstance.get(`/excess_types`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            excess_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}` }
            });

        }
    }).catch(error => {
        excess_types = []
    })

    return excess_types;
}
export async function getInsuranceCompanies() {

    await axiosInstance.get(`/companies/get_insurance_companies`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            insurance_companies = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, name: `${row.name}`, address: `${row.address}`, phone: `${row.phone}`, email: `${row.email}`, short_code: `${row.short_code}` }
            });

        }
    }).catch(error => {
        insurance_companies = []
    })

    return insurance_companies;
}
export async function getBanks() {

    await axiosInstance.get(`/companies/get_banks`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            banks = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, name: `${row.name}`, address: `${row.address}`, phone: `${row.phone}`, email: `${row.email}`, short_code: `${row.short_code}` }
            });

        }
    }).catch(error => {
        banks = []
    })

    return banks;
}
export async function getBrokerageFirms() {

    await axiosInstance.get(`/companies/get_brokerage_firms`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            brokerage_firms = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, name: `${row.name}`, address: `${row.address}`, phone: `${row.phone}`, email: `${row.email}`, short_code: `${row.short_code}` }
            });

        }
    }).catch(error => {
        brokerage_firms = []
    })

    return brokerage_firms;
}
export async function getInsuranceCompaniesData() {

    await axiosInstance.get(`/companies/get_insurance_companies?sticker_balance=true`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            insurance_companies_data = response.data
            return response.data
        }
    }).catch(error => {
        insurance_companies_data = []
    })

    return insurance_companies_data;
}
export async function getDiscountTypes() {

    await axiosInstance.get(`/discount_types/`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            discount_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, order: row.order }
            });
        }
    }).catch(error => {
        discount_types = []
    })

    return discount_types;
}
export async function getLoadingTypes() {

    await axiosInstance.get(`/loading_types/`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            console.log("LOADING DATA", response)
            loading_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, order: row.order }
            });
        }
    }).catch(error => {
        loading_types = []
    })

    return loading_types;
}

export async function getCustomerTypes() {

    await axiosInstance.get(`/customer_types/`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            console.log("LOADING DATA", response)
            customer_types = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}` }
            });
        }
    }).catch(error => {
        customer_types = []
    })

    return customer_types;
}

export async function getStickerBalance() {

    await axiosInstance.get(`/companies/get_sticker_balance`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            sticker_balance = response.data.balance
            total_stickers = response.data.total
        }
    }).catch(error => {
        sticker_balance = 0;
        total_stickers = 0;
    })
    return [sticker_balance, total_stickers];
}
export async function getSmsBalance() {

    await axiosInstance.get(`/sms_messages/get_sms_balance`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            sms_balance = response.data.data
            
        }
    }).catch(error => {
        sms_balance = 0;
        
    })
    return sms_balance;
}
export async function getCustomersCount() {

    await axiosInstance.get(`/companies/get_all_customers_count`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            customers_count = response.data.count
        }
    }).catch(error => {
        customers_count = 0
    })
    return customers_count;
}
export async function getComprehensivePolicies() {

    await axiosInstance.get(`/companies/get_all_comprehensive_policies_count`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            comprehensive_policies_count = response.data.count
        }
    }).catch(error => {
        comprehensive_policies_count = 0
    })
    return comprehensive_policies_count;
}

export async function getThirdPartyFnFPolicies() {

    await axiosInstance.get(`/companies/get_all_third_party_policies_fire_and_theft_count`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            third_party_fnf_count = response.data.count
        }
    }).catch(error => {
        third_party_fnf_count = 0
    })
    return third_party_fnf_count;
}
export async function getGrossPremiumWritten() {

    await axiosInstance.get(`/companies/get_gross_premium_written`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            gross_premium_written = response.data.count
        }
    }).catch(error => {
        gross_premium_written = 0
    })
    return gross_premium_written;
}

export async function getTotalStickerIssued() {

    await axiosInstance.get(`/companies/get_issued_stickers`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            stickers_issued = response.data.count
        }
    }).catch(error => {
        stickers_issued = 0
    })
    return stickers_issued;
}
export async function getTotalCompletedStickerPurchases() {
    await axiosInstance.get(`/companies/get_completed_sticker_purchases`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            completedStickerPurchases = response.data.count
        }
    }).catch(error => {
        completedStickerPurchases = 0
    })
    return completedStickerPurchases;
}
export async function getThirdPartyPolicies() {

    await axiosInstance.get(`/companies/get_all_third_party_policies_count`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {
            third_party_count = response.data.count
        }
    }).catch(error => {
        third_party_count = 0
    })
    return third_party_count;
}
export async function getCompanies() {

    await axiosInstance.get(`/companies/`).then((response) => {

        if (response.status == "UNAUTHENTICATED") {
        }
        else {

            companies = response.data.map((row, i) => {
                return { value: row.id, label: `${row.name}`, uuid: row.uuid }
            });
            console.log(companies)
        }
    }).catch(error => {
        sticker_balance = []
    })
    return companies;
}

export async function getCompany() {
    var company = await localStorage.getItem("company").then(data => {
        return data;
    });
}