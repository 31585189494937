import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import axiosInstance, { errorMessages } from './components/Common/Axios'
import { toast, ToastContainer } from 'react-toastify';
import MyToast from './components/Common/MyToast'
import { Provider } from 'react-redux';

import App from './App';

import './i18n';


import configureStore from './store/store';
import { clear_credentials } from './store/actions/actions';
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <ToastContainer />
        <App />
    </Provider>,
    document.getElementById('app')
);

const { dispatch } = store; // direct access to redux store.
axiosInstance.interceptors.response.use(
    response => {
        if (response.config.method == "post") {
            var text = response.data.message ? response.data.message : "Success"
            toast(<MyToast title="Success" text={text} />, {
                type: "success",
                position: 'top-right',
            })
        }
        else if (response.config.method == "put") {
            var text = response.data.message ? response.data.message : "Success"
            toast(<MyToast title="Success" text={text} />, {
                type: "success",
                position: 'top-right',
            })
        }
        else if (response.config.method == "get") {

        }
        else {
            console.log(response.data.message)

            var text = response.data.message ? response.data.message : "Success"
            toast(<MyToast title="Success" text={text} />, {
                type: "success",
                position: 'top-right',
            })
        }

        return response
    },
    error => {

        if (error.response.status == 400) {
            toast(<MyToast title="Failed Validation" text={errorMessages(error.response.data)} />, {
                type: "error",
                position: 'top-right',
            })
        }
        else if (error.response.status == 401) {
            // localStorage.removeItem("token")
            // localStorage.removeItem("uid")
            // localStorage.removeItem("client")
            // localStorage.removeItem("user")
            // localStorage.removeItem("company")
            // localStorage.removeItem("angle-store-key")

            dispatch(clear_credentials(error));
            // this.props.actions.clear_credentials();
        }
        else if (error.response.status == 403) {

            toast(<MyToast title="Unauthorized Access" text={errorMessages(error.response.data)} />, {
                type: "error",
                position: 'top-right',
            })
        }
        else if (error.response.status == 423) {

            toast(<MyToast title="Password Expired/Account Locked" text={errorMessages(error.response.data)} />, {
                type: "error",
                position: 'top-right',
            })
            this.props.actions.clear_credentials.dispatch();


        }
        else {
            toast(<MyToast title="Error(s) Occured" text={errorMessages(error.response.data)} />, {
                type: "error",
                position: 'top-right',
            })
            return error
        }
        // console.log(error.response);
        // return error
    }
)
