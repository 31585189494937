import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares'

import { updateTheme } from './middlewares/themes.middleware.js';

import { persistedState, saveState } from './persisted.store.js';
import { sagaMiddleware } from './middlewares/middlewares'
import { watchLogin } from './sagas/saga'

export default function configureStore() {
    var newPersistedState = persistedState ? { ...persistedState, authentication: { ...persistedState.authentication, loginErrorMessage: "", password_expired: false } } : {};
    const store = createStore(
        reducers,
        newPersistedState, // second argument overrides the initial state
        applyMiddleware(
            ...middlewares
        )
    );
    sagaMiddleware.run(watchLogin)

    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState());
    });

    // Update the initial theme
    updateTheme(store.getState())
    return store;
}