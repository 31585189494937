import React, { Component } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, FormGroup, Input, } from 'reactstrap'
import axios from '../Common/Axios'


export default class FeedbackModal extends Component {
    state = {
        subject: '',
        message: '',

    }
    onChange = e => {
        var name = e.target.name;

        this.setState({
            ...this.state,
            [name]: e.target.value
        })
    }
    send_feedback = e => {
        e.preventDefault();
        console.log("ChangingPassword");
        axios.post("/feedbacks/", { ...this.state }).then(response => {

            console.log("Sending feedback the Password")
            console.log(response)
            if (response != undefined) {
                this.props.toggle();
                this.setState({
                    ...this.state,
                    subject: '',
                    message: '',
                })
            }


        }).catch(error => {

            // this.props.toggle();
        })
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={'modal-lg'}>
                <ModalHeader toggle={this.props.toggle}>Submit Feedback </ModalHeader>
                <ModalBody>
                    <h3>Thank you</h3>
                    <p>For taking the time to give us feedback. Kindly fill and submit the form below:</p>
                    <FormGroup row>
                        <label className="col-xl-12 col-form-label">Subject</label>
                        <div className="col-xl-12">
                            <Input type="text" name='subject' placeholder="" onChange={this.props.onChange} value={this.state.subject} onChange={this.onChange} />
                        </div>
                    </FormGroup>
                    <FormGroup row>
                        <label className="col-xl-12 col-form-label">Feedback</label>
                        <div className="col-xl-12">
                            <Input type="textarea" rows={10} name='message' placeholder="" onChange={this.props.onChange} value={this.state.message} onChange={this.onChange} />
                        </div>
                    </FormGroup>


                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.send_feedback}>Send Feedback</Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal >
        )
    }
}
