import React, { Component } from 'react'

export default class MyToast extends Component {
    render() {

        if (this.props.text.constructor.name == "String") {
            return (
                <div>
                    <h5>{this.props.title}</h5>
                    <ul>
                        <li> {this.props.text} </li>
                    </ul>
                </div >
            )
        }
        else {
            return (
                <div>
                    <h5>{this.props.title}</h5>
                    <ul>
                        {this.props.text.map((row, i) => { return <li>{row}</li> })}
                    </ul>
                </div >
            )
        }

    }
}
