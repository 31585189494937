import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

const BankPayment = lazy(() => import('./components/BankPayments/BankPayment'));
const BankPayments = lazy(() => import('./components/BankPayments/BankPayments'));
const MobileDevices = lazy(() => import('./components/MobileDevices/MobileDevices'));
const MobileDevice = lazy(() => import('./components/MobileDevices/MobileDevice'));
// import BaseHorizontal from './components/Layout/BaseHorizontal';
/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;
const Login = lazy(() => import("./components/Pages/Login"));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const MotorPolicyPromise = import('./components/Policies/MotorPolicy');
const MotorPolicy = lazy(() => MotorPolicyPromise);
const MotorPolicies = lazy(() => import('./components/Policies/MotorPolicies'));
const MotorRenewal = lazy(() => import('./components/Policies/MotorRenewal'));
const MotorRenewals = lazy(() => import('./components/Policies/MotorRenewals'));
const MotorEndorsement = lazy(() => import("./components/MotorEndorsements/MotorEndorsement"));
const MotorEndorsements = lazy(() => import("./components/MotorEndorsements/MotorEndorsements"))
const Company = lazy(() => import('./components/Companies/Company'));
const PasswordExpired = lazy(() => import('./components/Account/PasswordExpired'));
const Companies = lazy(() => import('./components/Companies/Companies'));
const Users = lazy(() => import('./components/Users/Users'));
const UserActivities = lazy(() => import('./components/Users/UserActivities'));
const ApiAccounts = lazy(() => import('./components/ApiAccounts/ApiAccounts'));
const ApiAccount = lazy(() => import('./components/ApiAccounts/ApiAccount'));
const Stickers = lazy(() => import('./components/Stickers/Stickers'));
const Sticker = lazy(() => import('./components/Stickers/Sticker'));
const StickerPurchases = lazy(() => import('./components/Stickers/StickerPurchases'));
const StickerBalance = lazy(() => import('./components/Stickers/Balance'));
const StickerPurchase = lazy(() => import('./components/Stickers/StickerPurchase'));
const User = lazy(() => import('./components/Users/User'));
const Role = lazy(() => import('./components/Roles/Role'));
const Roles = lazy(() => import('./components/Roles/Roles'));
const Branch = lazy(() => import('./components/Branches/Branch'));
const Branches = lazy(() => import('./components/Branches/Branches'));
const Receipts = lazy(() => import('./components/Receipts/Receipts'));
const Receipt = lazy(() => import('./components/Receipts/Receipt'));
const Schedule = lazy(() => import('./components/Setups/Schedules/Schedule'));
const Schedules = lazy(() => import('./components/Setups/Schedules/Schedules'));
const ShortRate = lazy(() => import('./components/Setups/ShortRates/ShortRate'));
const ShortRates = lazy(() => import('./components/Setups/ShortRates/ShortRates'));
const Tarriff = lazy(() => import('./components/Setups/Tarriffs/Tarriff'));
const Tarriffs = lazy(() => import('./components/Setups/Tarriffs/Tarriffs'));
const CoverType = lazy(() => import('./components/Setups/CoverTypes/CoverType'));
const CoverTypes = lazy(() => import('./components/Setups/CoverTypes/CoverTypes'));
const BodyType = lazy(() => import('./components/Setups/BodyTypes/BodyType'));
const BodyTypes = lazy(() => import('./components/Setups/BodyTypes/BodyTypes'));
const ExchangeRate = lazy(() => import('./components/Setups/ExchangeRates/ExchangeRate'));
const Customer = lazy(() => import('./components/Customers/Customer'));
const Customers = lazy(() => import('./components/Customers/Customers'));
const ExchangeRates = lazy(() => import('./components/Setups/ExchangeRates/ExchangeRates'));
const Currency = lazy(() => import('./components/Setups/Currencies/Currency'));
const Currencies = lazy(() => import('./components/Setups/Currencies/Currencies'));
const StickerPrice = lazy(() => import('./components/Setups/StickerPrices/StickerPrice'));
const StickerPrices = lazy(() => import('./components/Setups/StickerPrices/StickerPrices'));
const Intermediary = lazy(() => import('./components/Intermediaries/Intermediary'));
const Intermediaries = lazy(() => import('./components/Intermediaries/Intermediaries'));
const UsageType = lazy(() => import('./components/Setups/UsageTypes/UsageType'));
const UsageTypes = lazy(() => import('./components/Setups/UsageTypes/UsageTypes'));
const IssuedStickers = lazy(() => import('./components/Reports/IssuedStickers'));
const CancelledStickers = lazy(() => import("./components/Reports/CancelledStickers"));
const PaymentsByBank = lazy(() => import("./components/Reports/PaymentsByBank"));
const SmsMessages = lazy(() => import("./components/Reports/SmsMessages"));
const CancelledPolicies = lazy(() => import("./components/Reports/CancelledPolicies"));
const PolicyListing = lazy(() => import("./components/Reports/PolicyListing"));
const ActivePolicies = lazy(() => import("./components/Reports/ActivePolicies"));
const Production = lazy(() => import('./components/Reports/Production'));
const ProductionCoverType = lazy(() => import('./components/Reports/ProductionCoverType'));
const ProductionUsage = lazy(() => import('./components/Reports/ProductionUsage'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const Error500 = lazy(() => import('./components/Pages/Error500'));
const Maintenance = lazy(() => import('./components/Pages/Maintenance'));
const CompanyDetails = lazy(() => import("./components/Companies/CompanyDetails"))
const NotificationType = lazy(() => import('./components/NotificationTypes/NotificationType'));
const NotificationTypes = lazy(() => import('./components/NotificationTypes/NotificationTypes'));
const HistoricalUploads = lazy(() => import('./components/HistoricalUploads/HistoricalUploads'));
const HistoricalUpload = lazy(() => import('./components/HistoricalUploads/HistoricalUpload'));
const NcdConfirmations = lazy(() => import('./components/NcdConfirmations/NcdConfirmations'));
const NcdConfirmation = lazy(() => import('./components/NcdConfirmations/NcdConfirmation'));
const NCDConfirmationsReport = lazy(() => import("./components/Reports/NCDConfirmationsReport"))
// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)} />

                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    {/*Dashboard*/}

                                    {/*Policies */}
                                    <Route path="/motor_policies/new" component={waitFor(MotorPolicy)} />
                                    <Route path="/motor_policies/:id/endorse/:endorsement_id/:endorsement_action" component={waitFor(MotorPolicy)} />
                                    <Route path="/motor_policies/:id/endorse/:endorsement_id" component={waitFor(MotorPolicy)} />
                                    <Route path="/motor_policies/:id" component={waitFor(MotorPolicy)} />
                                    <Route path="/motor_policies" component={waitFor(MotorPolicies)} />
                                    <Route path="/receipts/new" component={waitFor(Receipt)} />
                                    <Route path="/receipts/:id" component={waitFor(Receipt)} />
                                    <Route path="/receipts" component={waitFor(Receipts)} />
                                    {/*Setups */}
                                    <Route path="/schedules/new" component={waitFor(Schedule)} />
                                    <Route path="/schedules/:id" component={waitFor(Schedule)} />
                                    <Route path="/schedules" component={waitFor(Schedules)} />
                                    <Route path="/short_rates/new" component={waitFor(ShortRate)} />
                                    <Route path="/short_rates/:id" component={waitFor(ShortRate)} />
                                    <Route path="/short_rates" component={waitFor(ShortRates)} />
                                    <Route path="/tarriffs/new" component={waitFor(Tarriff)} />
                                    <Route path="/tarriffs/:id" component={waitFor(Tarriff)} />
                                    <Route path="/tarriffs" component={waitFor(Tarriffs)} />
                                    <Route path="/cover_types/new" component={waitFor(CoverType)} />
                                    <Route path="/cover_types" component={waitFor(CoverTypes)} />
                                    <Route path="/companies/new" component={waitFor(Company)} />
                                    <Route path="/companies/:id/details" component={waitFor(CompanyDetails)} />
                                    <Route path="/companies/:id" component={waitFor(Company)} />

                                    <Route path="/companies" component={waitFor(Companies)} />

                                    <Route path="/renewals" component={waitFor(MotorRenewals)} />
                                    <Route path="/endorsements/new" component={waitFor(MotorEndorsement)} />
                                    <Route path="/motor_policies/:id/endorse/:endorsement_id" component={waitFor(MotorPolicy)} />
                                    <Route path="/endorsements/:id" component={waitFor(MotorEndorsement)} />

                                    <Route path="/endorsements/" component={waitFor(MotorEndorsements)} />
                                    <Route path="/body_types/new" component={waitFor(BodyType)} />
                                    <Route path="/body_types/:id" component={waitFor(BodyType)} />
                                    <Route path="/body_types" component={waitFor(BodyTypes)} />
                                    <Route path="/exchange_rates/new" component={waitFor(ExchangeRate)} />
                                    <Route path="/exchange_rates/:id" component={waitFor(ExchangeRate)} />
                                    <Route path="/exchange_rates" component={waitFor(ExchangeRates)} />
                                    <Route path="/currencies/new" component={waitFor(Currency)} />
                                    <Route path="/currencies/:id" component={waitFor(Currency)} />
                                    <Route path="/currencies" component={waitFor(Currencies)} />
                                    <Route path="/sticker_prices/new" component={waitFor(StickerPrice)} />
                                    <Route path="/sticker_prices/:id" component={waitFor(StickerPrice)} />
                                    <Route path="/sticker_prices" component={waitFor(StickerPrices)} />
                                    <Route path="/bank_payments/new" component={waitFor(BankPayment)} />
                                    <Route path="/bank_payments/:id" component={waitFor(BankPayment)} />
                                    <Route path="/bank_payments" component={waitFor(BankPayments)} />
                                    <Route path="/usage_types/new" component={waitFor(UsageType)} />
                                    <Route path="/usage_types/:id" component={waitFor(UsageType)} />
                                    <Route path="/usage_types" component={waitFor(UsageTypes)} />
                                    <Route path="/intermediaries/new" component={waitFor(Intermediary)} />
                                    <Route path="/intermediaries/:id" component={waitFor(Intermediary)} />
                                    <Route path="/intermediaries" component={waitFor(Intermediaries)} />
                                    <Route path="/reports/production" component={waitFor(Production)} />
                                    <Route path="/reports/production_cover_types" component={waitFor(ProductionCoverType)} />
                                    <Route path="/reports/production_usage" component={waitFor(ProductionUsage)} />
                                    <Route path="/reports/issued_stickers" component={waitFor(IssuedStickers)} />
                                    <Route path="/reports/cancelled_stickers" component={waitFor(CancelledStickers)} />
                                    <Route path="/reports/cancelled_policies" component={waitFor(CancelledPolicies)} />
                                    <Route path="/reports/active_policies" component={waitFor(ActivePolicies)} />
                                    <Route path="/reports/policies_listing" component={waitFor(PolicyListing)} />
                                    <Route path="/reports/payments_by_bank" component={waitFor(PaymentsByBank)} />
                                    <Route path="/reports/ncd_confirmations" component={waitFor(NCDConfirmationsReport)} />
                                    <Route path="/reports/sms_messages" component={waitFor(SmsMessages)} />
                                    {/*Customers*/}
                                    <Route path="/customers/new" component={waitFor(Customer)} />
                                    <Route path="/customers/:id" component={waitFor(Customer)} />
                                    <Route path="/customers" component={waitFor(Customers)} />
                                    {/*Branches*/}
                                    <Route path="/branches/new" component={waitFor(Branch)} />
                                    <Route path="/branches/:id" component={waitFor(Branch)} />
                                    <Route path="/branches" component={waitFor(Branches)} />
                                    {/*Users */}
                                    <Route exact path="/user_activities/" component={waitFor(UserActivities)} />
                                    <Route exact path="/users/new" component={waitFor(User)} />
                                    <Route path="/users/:id" component={waitFor(User)} />
                                    <Route path="/users" component={waitFor(Users)} />
                                    <Route exact path="/roles/new" component={waitFor(Role)} />
                                    <Route exact path="/roles/:id" component={waitFor(Role)} />
                                    <Route path="/roles" component={waitFor(Roles)} />
                                    <Route path="/api_accounts/new" component={waitFor(ApiAccount)} />
                                    <Route path="/api_accounts/:id" component={waitFor(ApiAccount)} />
                                    <Route path="/api_accounts" component={waitFor(ApiAccounts)} />
                                    {/*Stickers */}
                                    <Route path="/stickers" component={waitFor(Stickers)} />
                                    <Route path="/sticker_balance" component={waitFor(StickerBalance)} />
                                    <Route path="/sticker_purchases/new" component={waitFor(StickerPurchase)} />
                                    <Route path="/sticker_purchases/:id" component={waitFor(StickerPurchase)} />
                                    <Route path="/sticker_purchases" component={waitFor(StickerPurchases)} />
                                    <Route path="/users" component={waitFor(Users)} />
                                    <Route path="/password_expired" component={waitFor(PasswordExpired)} />
                                    <Route path="/notification_types/:id" component={waitFor(NotificationType)} />
                                    <Route path="/notification_types" component={waitFor(NotificationTypes)} />
                                    <Route path="/historical_uploads/new" component={waitFor(HistoricalUpload)} />
                                    <Route path="/ncd_confirmations/new" component={waitFor(NcdConfirmation)} />
                                    <Route path="/ncd_confirmations/:id" component={waitFor(NcdConfirmation)} />
                                    <Route path="/ncd_confirmations/" component={waitFor(NcdConfirmations)} />
                                    <Route path="/historical_uploads/:id" component={waitFor(HistoricalUpload)} />
                                    <Route path="/historical_uploads" component={waitFor(HistoricalUploads)} />
                                    <Route path="/fleet_uploads" component={waitFor(HistoricalUploads)} />
                                    <Route path="/devices/:id" component={waitFor(MobileDevice)} />
                                    <Route path="/devices" component={waitFor(MobileDevices)} />
                                    
                                    <Route path="/" component={waitFor(Dashboard)} />
                                    <Redirect to="/" />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);

