import React, { Component } from 'react';

import { Input } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import FormValidator from '../Forms/FormValidator.js';
// import { statement } from '@babel/template';
import { ScaleLoader } from 'react-spinners';

class Login extends Component {

    state = {
        formLogin: {
            email: '',
            password: ''
        }
    }

    /**
     * Validate input using onChange event
     * @param  {String} formName The name of the form in the state object
     * @return {Function} a function used for the event
     */
    handleCopyPaste = event => {
        event.preventDefault();
        alert("Disabled for Security Reasons")
    }
    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    }
    componentDidMount = () => {
        this.props.actions.clear_login_loading();
    }

    onSubmit = e => {
        console.log("Loggin In")
        console.log(this.state)
        this.props.actions.begin_login();
        this.props.actions.login(this.state.formLogin.email, this.state.formLogin.password)
        // this.props.actions.loginUser
        e.preventDefault();
    }
    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return this.state[formName] &&
            this.state[formName].errors &&
            this.state[formName].errors[inputName] &&
            this.state[formName].errors[inputName][method]
    }
    render() {
        console.log(this.props)
        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                        <a href="">
                            <img className="block-center rounded login_logo" src="img/logo.png" alt="Logo" />
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">SIGN IN TO CONTINUE.</p>
                        <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
                            {console.log("THIS IS PROPS", this.props)}
                            {this.props.errorMessage ? <div className="alert alert-danger">{this.props.errorMessage}</div> : ""}

                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input type='hidden' name='email' className={'hidden'} value="sdfldskf" />
                                    <Input type="text"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="Enter Username"
                                        invalid={this.hasError('formLogin', 'email', 'required') || this.hasError('formLogin', 'email', 'email')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formLogin.email} />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                        </span>
                                    </div>
                                    {this.hasError('formLogin', 'email', 'required') && <span className="invalid-feedback">Field is required</span>}
                                    {this.hasError('formLogin', 'email', 'email') && <span className="invalid-feedback">Field must be valid email</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input type='hidden' name='password' className={'hidden'} value="password" />
                                    <Input type="password"
                                        id="id-password"
                                        name="password"
                                        className="border-right-0"
                                        placeholder="Password"
                                        invalid={this.hasError('formLogin', 'password', 'required')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        onCopy={this.handleCopyPaste}
                                        onCut={this.handleCopyPaste}
                                        onPaste={this.handleCopyPaste}
                                        autoComplete=""
                                        value={this.state.formLogin.password}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-lock"></em>
                                        </span>
                                    </div>
                                    <span className="invalid-feedback">Field is required</span>
                                </div>
                            </div>
                            {/* <div className="clearfix">
                                <div className="checkbox c-checkbox float-left mt-0">
                                    <label>
                                        <input type="checkbox" value="" name="remember" />
                                        <span className="fa fa-check"></span>Remember Me</label>
                                </div>

                            </div> */}
                            <button className="btn btn-block btn-primary mt-3" type="submit" disabled={this.props.loading}>{this.props.loading ? <React.Fragment><ScaleLoader


                                sizeUnit={"px"}
                                height={10}
                                color={'#fff'}
                                loading={this.state.loading}
                            />   </React.Fragment> : "Login"} </button>
                        </form>

                    </div>
                </div>
                <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>2020</span>
                    <span className="mx-2">-</span>
                    <span>National Insurance Commission</span>
                    <br />
                    <span>Powered by <a href='https://www.ecfatum.com' target='_blank'>Ecfatum Limited</a></span>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return (
        { user: state.authentication.user, token: state.authentication.token, errorMessage: state.authentication.loginErrorMessage, loading: state.authentication.loading }
    )
}

const mapDispatchToProps = (dispatch) => {
    return ({
        actions: bindActionCreators(actions, dispatch)
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
